<template>
  <div class="home">

    <a-layout>
        <a-layout-sider v-model="collapsed" :trigger="null" collapsible
          style="padding-top:20px;margin-left:10px;width:200px;background:#fff"
        >
          <kys-sidebar></kys-sidebar>
        </a-layout-sider>
        <a-layout>
          <a-layout-content
            :style="{ padding: '24px', background: '#fff', minHeight: '280px' }"
          >
          <div v-show="view == 'namelists'">
            <h1>Namelists</h1>
            <a-card style="max-width:1080px">
              <a-row>
                <a-col>
                  <a-table :columns="namelistTableColumns" :pagination="false" :data-source="namelists" :rowKey="'id'">
                    <a slot="name" slot-scope="text">{{ text }}</a>
                    <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
                    <span slot="tags" slot-scope="tags">
                      <a-tag
                        v-for="tag in tags"
                        :key="tag"
                        :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
                      >
                        {{ tag.toUpperCase() }}
                      </a-tag>
                    </span>
                    <span slot="subscribers" slot-scope="text, record">
                      {{ record.num_subscribers }}
                    </span>
                    <span slot="action" slot-scope="text, record">
                      <router-link :to="{path: 'n/' +  record.name}"><a-button  type="link">View</a-button></router-link>
                    </span>
                    <span slot="more" slot-scope="text, record">
                      <a-divider type="vertical" />
                      <a>Delete</a>
                      <a-divider type="vertical" />
                      <a class="ant-dropdown-link"> More actions <a-icon type="down" /> </a>
                    </span>
                  </a-table>
                </a-col>
              </a-row>
              <a-row type="flex" style="padding-top:30px" justify="center">
                <a-col>
                  <a-button @click="namelist_result_limit+=5; getNamelists()" >More</a-button>
                </a-col>
              </a-row>
              </a-card>
          </div>
          <div style="padding-top:30px" >
            <h1>Recent Files</h1>
            <a-card style="max-width:1080px">
              <a-row>
                <a-col>
                  <a-list
                    class="demo-loadmore-list"
                    item-layout="horizontal"
                    :data-source="files"
                  >
                    <a-list-item slot="renderItem" slot-scope="item, index">
                      <a-list-item-meta>
                        <a slot="title" @click="download_file(item)">{{ item.name }}</a>
                      </a-list-item-meta>
                    </a-list-item>
                  </a-list>
                </a-col>
              </a-row>
            </a-card>
          </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
  </div>
</template>

<script>
import KysSidebar from "../components/HomeSidebar";

const fb = require('../firebaseConfig.js')
const kysapi = require('../kysapi')

export default {
  name: 'Home',
  components: {
    KysSidebar
  },
  data() {
    return {
      user: fb.session.user,
      collapsed: false,
      view: 'namelists',
      namelists: [],
      namelist_result_limit: 5,
      files: [],
      namelistTableColumns: [
        {
          dataIndex: 'name',
          key: 'name',
          title:'Name'
        },
        {
          title: 'Subscribers',
          scopedSlots: { customRender: 'subscribers' },
          key: 'process_stats.subscribers',
        },
        {
          title: 'Action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
        {
          dataIndex: 'when_entered_date',
          key: 'when_entered_date',
          title:'Created'
        },
      ],
    }
  },
  mounted: async function () {
    this.getNamelists()
  },
  methods: {
    getNamelists: async function() {

      var response = await this.$kysapi.get('api/namelists?limit=' + this.namelist_result_limit, this.$auth)
      console.log(this.$route)

      const { data } = response
      const { namelists } = this
      console.log('namelists', namelists )

      const existing = namelists.map((e) => { return e.id })

      data.namelists.forEach((namelist, i) => {
        if(this.$route.path === '/demo'){
          if(namelist.name === 'kysgui_demo'){
              namelists.push(namelist)
          }
        } else {
          if(namelist.status != 'ARCHIVED' && !existing.includes(namelist.id)){
              namelists.push(namelist)
              this.files.push(...namelist.files)
          }
        }
      });

      console.log(this.files)
    },
    download_file: async function(file){
      console.log('api/file/' + file.key)
      const response = await this.$kysapi.get('api/file/' + file.key, this.$auth, {responseType: "blob"})
      console.log(response)
      const blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = file.name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  }
}
</script>
