<template>
  <div>
    <h1 style="margin-left:20px">Namelists</h1>
    <a-menu mode="inline" :default-selected-keys="['0']">
      <a-menu-item>
        <router-link to="/">View</router-link>
      </a-menu-item>
    </a-menu>
    <a-menu mode="inline" :default-selected-keys="['1']">
      <a-menu-item>
        <router-link to="/files">Client Files</router-link>
      </a-menu-item>
    </a-menu>

    <h1 style="margin-left:20px">Settings</h1>
    <a-menu mode="inline">
      <a-menu-item>
        <router-link to="/usecases">Usecases</router-link>
      </a-menu-item>
    </a-menu>
    <div v-if="$auth.user.profile.role == 'admin'">
      <h1 style="margin-left:20px">Internal Process</h1>
      <a-menu mode="inline">
        <a-menu-item>
          <router-link to="/internal/linkedin">Linkedin</router-link>
        </a-menu-item>
      </a-menu>
      <a-menu mode="inline">
        <a-menu-item>
          <router-link to="/internal/queues">Queues</router-link>
        </a-menu-item>
      </a-menu>

    </div>
</div>
</template>
<style>
  .strong {
    color:black;
    font-weight:500;
    font-size:13px;
  }

  .industry {
    font-style: italic;
  }
</style>
<script>

export default {
  name: 'HomeSidebar',
  components: {
  },
  data: ()=>{
    return {
    }
  },
  props: {
  },
  methods: {

  }
}
</script>
