import Vue from 'vue'
import App from './App.vue'
import router from './router'
//
// import { ButtonPlugin } from '@syncfusion/ej2-vue-buttons';
// import { TabPlugin } from '@syncfusion/ej2-vue-navigations';
// import { DashboardLayoutPlugin } from "@syncfusion/ej2-vue-layouts";
// import { GridPlugin, VirtualScroll, Sort, Filter, Selection, GridComponent } from "@syncfusion/ej2-vue-grids";

// Import the Auth0 configuration
import { domain, clientId } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

import { kysapi } from "./kysapi";

import VueApexCharts from 'vue-apexcharts'


import PubNubVue from 'pubnub-vue';

Vue.use(PubNubVue, {
  publishKey : "pub-c-8ba99d46-d883-4d5d-8d03-1c9e111e7172",
  subscribeKey : "sub-c-512dc2c2-8778-11eb-99bb-ce4b510ebf19",
});

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import JsonViewer from 'vue-json-viewer/ssr'
import 'vue-json-viewer/style.css'

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    console.log(appState)
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});


// Import JsonViewer as a Vue.js plugin
Vue.use(JsonViewer)
Vue.use(Antd);
// Vue.use(GridPlugin);
// Vue.use(DashboardLayoutPlugin);
// Vue.use(TabPlugin);
// Vue.use(ButtonPlugin);
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

//
Vue.config.productionTip = false

Vue.prototype.$eventHub = new Vue();


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
