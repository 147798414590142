import firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/functions'

const axios = require('axios')

const firebaseConfig = {
  apiKey: "AIzaSyDgb75DejJfqzKGavKyND4TPhKg-9TC1Ac",
  authDomain: "kys-internal.firebaseapp.com",
  projectId: "kys-internal",
  storageBucket: "kys-internal.appspot.com",
  messagingSenderId: "741840945617",
  appId: "1:741840945617:web:a871acc9b226bc31ea60f0"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// firebase utils
//const db = firebase.firestore()
// db.enablePersistence()
//   .catch(function (err) {
//     if (err.code === 'failed-precondition') {
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a a time.
//       // ...
//     } else if (err.code === 'unimplemented') {
//       // The current browser does not support all of the
//       // features required to enable persistence
//       // ...
//     }
//   })

const auth = firebase.auth()
const functions = firebase.functions()

const session = {
  user: {
    name: null,
    uid: null,
    email: null,
    avatar: ''
  },
  token: '',
  ready: false
}

session.ready = new Promise(((resolve) => {
  auth.onAuthStateChanged((authorizedUser) => {
    console.log('authStateChanged', authorizedUser)
    if (authorizedUser) {
      // session.user.uid = authorizedUser.uid
      session.user.name = authorizedUser.displayName
      session.user.uid = authorizedUser.email
      session.user.email = authorizedUser.email
      session.user.avatar =  authorizedUser.photoURL
      authorizedUser.getIdToken(true).then(function (idToken) {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${idToken}` }
        session.token = idToken
        resolve(session.user)
      })
    } else {
      session.user.name = null
      session.user.uid = null
      session.user.email = null
      session.user.avatar =  null  
    }
  })
}))

// firebase collections
// const settingsCollection = db.collection('settings')

export {
  auth,
  session,
  functions
}
