<template>
  <div id="app">
    <a-layout>
      <a-layout-header class="header" style="line-height:55px;height:55px">
        <div class="logo">
          <a href="/">
            <img src="@/assets/prosparency-splash-logo-dark.png" height="50px"/>
          </a>
        </div>
        <a-menu
          theme="dark"
          align="right"
          mode="horizontal"
          :style="{ lineHeight: '55px' }"
        >
        <a-menu-item key="1" v-if="user.name">
            <span class="nav-text">{{ user.name }}</span>
          </a-menu-item>
        <a-menu-item key="2">
            <a-icon type="logout" />
             <span class="nav-text" @click="logout">Logout</span>
           </a-menu-item>
         </a-menu>
      </a-layout-header>
      <router-view/>
    </a-layout>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.header {
  height:50px;
}

.logo {
  display:inline;
  max-width:100px;
  float:left;
}
</style>

<script>

const fb = require('./firebaseConfig.js')

export default {
  data() {
    return {
      user:  this.$auth.user,
      collapsed: false,
    };
  },
  methods: {
    logout: function (){
      this.$auth.logout()
      //fb.auth.signOut()
    }
  }
};
</script>
